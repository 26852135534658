import { ElButton, ElNotification, ElPagination } from "element-plus";
export default {
  components: {
    ElButton,
    ElPagination
  },
  methods: {
    // 可以写入全局方法等
    GlobalElNotification({
      title,
      message,
      type = "success",
      duration = 4500,
      showClose = true,
      dangerouslyUseHTMLString = false
    }) {
      ElNotification({
        title,
        message,
        type,
        duration,
        showClose,
        dangerouslyUseHTMLString
      });
    }
  }
};