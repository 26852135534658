const Home = () => import("@/views/Home");
const routes = [
    {
        path: "/",
        redirect: {
            name: "home",
        },
    },
    {
        path: "/",
        name: "home",
        component: Home,
    },
];
export default routes;
